import React from 'react'
import ProjectList from './ProjectList';
import { useStaticQuery, graphql } from 'gatsby'

const getAllProjects = graphql`
    query {
    projects:allContentfulProjecten(sort: { fields: [createdAt], order: DESC }) {
        edges {
            node {
                project,
                land,
                slug,
                contentful_id,
                afbeeldingen {
                    fluid {
                        ...GatsbyContentfulFluid
                    }
                }
            }
        }
    }
}
`

const Projects = () => {
    const { projects } = useStaticQuery(getAllProjects)
    return (
        <ProjectList allProjects={projects} />
    )
}

export default Projects
