import React, { Component } from 'react'
import styles from '../../css/items.module.css'
import Project from './Project'
import Title from '../Title'
export default class ProjectList extends Component {

    state = {
        projects: [],
        sortedprojects: []
    }

    componentDidMount() {
        this.setState({
            projects: this.props.allProjects.edges,
            sortedprojects: this.props.allProjects.edges
        })
    }

    componentWillUnmount() {
        this.setState({
            projects: [],
            sortedprojects: []
        })
    }
    render() {
        return (
            <section className={styles.projects}>
                <Title title="Onze" subtitle="projecten" />
                <div className={styles.center} >
                    {
                        this.state.sortedprojects.map(({node}) => {
                            return <Project key={node.contentful_id} singleProject={node} />
                        })
                    }
                </div>
            </section>
        )
    }
}
