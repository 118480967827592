import React from 'react'
import Image from 'gatsby-image'
import styles from '../../css/project.module.css'
import {FaMap} from 'react-icons/fa'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const Project = ({ singleProject }) => {
    const { project, land, afbeeldingen, slug } = singleProject;
    let mainImage = afbeeldingen[0].fluid;
    return (
        <article className={styles.project}>
            <div className={styles.imgContainer}>
                <Image fluid={mainImage} className={styles.img} alt="project" />
                <AniLink fade to={`/projecten/${slug}`} className={styles.link}>Details</AniLink>
            </div>
            <div className={styles.footer}>
                <h3>{project}</h3>
                <div className={styles.info}>
                    <h4 className={styles.country}>
                        <FaMap className={styles.icon} />
                        {land}
                    </h4>
                </div>
            </div>
        </article>
    )
}

export default Project
